import React from "react";
import Button from "./Button";
import {
  ksdivider_container,
  ksdivider_image,
  ksdivider_content,
} from "./styles/ksdivider.module.css";
const KsDividerContainer = () => {
  return (
    <div className={ksdivider_container}>
      <div className={ksdivider_image}>
        <img
          src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669801324/Unschool/ksdivider/Group_26821_1_j956sd.png"
          alt=""
        />
      </div>
      <div className={ksdivider_content}>


        <h3>Explore your interest from 150+ courses with a 7-day free trial</h3>


        <Button
          text={"Start Free Trial Today"}
          source={"https://www.unschool.in/skill-courses/"}
        />
      </div>
    </div>
  );
};

export default KsDividerContainer;
