import React from "react";
import {
  testimonialcard,
  testimonialimg,
  testimonialcardtop,
  testimonialname,
  testimonialcontent,
  quote,
  name,
  designation,
} from "./styles/testimonial.module.css";
const TestimonialCard = ({ item }) => {
  return (
    <section className={testimonialcard}>
      <div className={testimonialcardtop}>
        <div className={testimonialimg}>
          <img src={item.img} alt="profile" />
        </div>

        <a href={item.linkedin}>
          <img
            src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669703526/Unschool/testimonial/Path_2521_xjjvuq.png"
            alt="quote"
            className={quote}
          />
        </a>
      </div>

      <div className={testimonialcontent}>
        <div className={testimonialname}>
          <h3 className={name}>{item.customer}</h3>
        </div>
        <p>{item.review}</p>
      </div>
    </section>
  );
};

export default TestimonialCard;
