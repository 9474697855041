// extracted by mini-css-extract-plugin
export var Category_title = "course-module--Category_title--05d59";
export var activee = "course-module--activee--dd1e9";
export var containercourse = "course-module--containercourse--9d3d8";
export var courseContainer = "course-module--course-container--d0df2";
export var coursecard = "course-module--coursecard--542ab";
export var coursecategories = "course-module--coursecategories--d8113";
export var coursecontent = "course-module--coursecontent--8f7b4";
export var courseimg = "course-module--courseimg--7366d";
export var coursetitle = "course-module--coursetitle--84e82";
export var maincontainercourse = "course-module--maincontainercourse--3a6e8";