import React, { useState } from "react";
import JSON from "./data/faq.json";
import Question from "./Faq-question";
import {
  faqsection,
  faqinner,
  faqinnersection,
  faq_img,
  faq_title_desktop,
  faq_title_mobile,
} from "./styles/faq.module.css";
const Faq = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleToggle = (index) => {
    if (index === activeQuestion) {
      setActiveQuestion(null);
    } else {
      setActiveQuestion(index);
    }
  };

  return (
    <section className={faqsection}>
      <div className={faq_img}>
        <h3 className={faq_title_desktop}>Got A Question ?</h3>
        <img
          src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669879308/Unschool/faq/Group_26811_j8opo6.png"
          alt="faq"
        />
      </div>
      <div className={faqinner}>
        {/* <h2>Frequently Asked Questions</h2> */}
        <h3 className={faq_title_mobile}>Got A Question ?</h3>

        <div className={faqinnersection}>
          {JSON.Faqdata.map((item, index) => {
            return (
              <Question
                key={index}
                index={index}
                item={item}
                onToggle={handleToggle}
                activeQuestion={activeQuestion}
              ></Question>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
