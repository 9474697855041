import React from "react";
import { spotlight_card } from "./styles/spotlight.module.css";
const SpotlightCard = ({ item, index }) => {
  return (
    <section className={spotlight_card} key={index}>
      <img src={item.img} alt="" />
      <p>{item.text}.</p>
      <a href={item.link}>Read more</a>
    </section>
  );
};

export default SpotlightCard;
