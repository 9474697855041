import React from "react";
import FirstCardImage from "../img/images/cards/first-card.svg";
import SecondCardImage from "../img/images/cards/second-card.svg";
import ThirdCardImage from "../img/images/cards/third-card.svg";
import FourthCardImage from "../img/images/cards/fourth-card.svg";
import InternshipDesktop from "../img/images/cards/internship-desktop.svg";
import InternshipMobile from "../img/images/cards/internship-mobile.svg";
import "./sections/benefits.scss";
import BenefitsMiniCard from "./BenefitsMiniCard";
import AssuredInternshipCard from "./AssuredInternshipCard";
import { jobCompanies } from "../utils/constants";

const Flickity = typeof window !== `undefined` ? require("flickity") : null;
const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;

export const BenefitsSection = () => {
  const [flickSlideIndex, setFlickSlideIndex] = React.useState(0);

  React.useEffect(() => {
    const benefitsSectionCarousel = document.querySelector("#carousel");
    if (benefitsSectionCarousel && Flickity) {
      const flicker = new Flickity(benefitsSectionCarousel, {
        wrapAround: true,
        contain: true,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "center",
      });
      flicker.on("scroll", function (progress) {
        setFlickSlideIndex(progress * 75);
      });
    }
  }, []);

  return (
    <div id="benefits-section" className="benefits-section">
      <div className="benefits-cards-container">
        <div className="benefits-row">
          <div className="benefits-heading">Benefits of learning with us</div>
        </div>
        <div className="benefits-row">
          <BenefitsMiniCard
            numberText="01"
            numberColor="textBlue"
            cardColor="cardColorBlue"
            heading="Start from scratch"
            imageClass="blueImage"
            image={FirstCardImage}
            description="Want to learn something absolutely new? An open mind is the best place to build a palace! Evey course covers the subject from basics."
          />
          <BenefitsMiniCard
            numberText="02"
            numberColor="textGreen"
            cardColor="cardColorGreen"
            heading="Mentorship & Coaching"
            imageClass="greenImage"
            image={ThirdCardImage}
            description="Industry experts to guide you through your course and share their experience with you. Gain a friend in your field and a coach for life."
          />
        </div>
        <div className="benefits-row">
          <BenefitsMiniCard
            numberText="03"
            numberColor={`textYellow`}
            cardColor="cardColorYellow"
            heading="Build projects"
            imageClass="yellowImage"
            image={FourthCardImage}
            description="Internships, projects and practical teaching methods to help you become instantly employable and desirable by the top companies."
          />
          <BenefitsMiniCard
            numberText="04"
            numberColor="textLightBlue"
            cardColor="cardColorLightBlue"
            heading="Certificate on completion"
            imageClass="lightBlueImage"
            image={SecondCardImage}
            description="Recognized certificates to give your resume an edge. A blue-chip credential to enter your field with."
          />
        </div>
        <div className="benefits-row">
          <AssuredInternshipCard
            heading="Assured internship opportunities."
            description="We ensure that you take the first step into the industry with a reputed firm. From there, you’re equipped to fly."
            buttonText="EXPLORE INTERNSHIPS"
            image={InternshipDesktop}
            mobileImage={InternshipMobile}
          />
        </div>
      </div>

      <div className="benefits-section-internships">
        <h2 id="trending-section-heading">
          Unschoolers have found their way into internships at
        </h2>
        <div
          id="benefits-section-logos-container"
          className={"logos-row-container"}
        >
          <div className="logos-row-logos-container benefits-section-companies">
            {typeof window !== "undefined" && (
              <Marquee>
                {jobCompanies.map((company, index) => (
                  <img
                    key={index}
                    src={company.logoUrl}
                    className="benefits-section-logo"
                    alt={company.name}
                  />
                ))}
              </Marquee>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BenefitsSection;
