import React from "react";
import {
  coachcard,
  coachimg,
  coachcontent,
  coachtitle,
  coachlogo,
  coachName,
  coachDesignation,
} from "./styles/coach.module.css";

const CoachCard = ({ item }) => {
  return (
    <section className={coachcard}>
      <div className={coachimg}>
        <img src={item.img} alt="" />
      </div>
      <div className={coachcontent}>
        <div className={coachtitle}>
          <h3 className={coachName}>{item.Coachname}</h3>
          <p className={coachDesignation}>{item.title}</p>
        </div>
        <div className={coachlogo}>
          <img src={item.logo} alt="" />
        </div>
      </div>
    </section>
  );
};

export default CoachCard;
