// extracted by mini-css-extract-plugin
export var answer = "faq-module--answer--6ef3a";
export var answersection = "faq-module--answersection--ce31c";
export var btn = "faq-module--btn--81a8e";
export var faq_img = "faq-module--faq_img--88aa5";
export var faq_title_desktop = "faq-module--faq_title_desktop--b388f";
export var faq_title_mobile = "faq-module--faq_title_mobile--45e75";
export var faqinner = "faq-module--faqinner--c03b6";
export var faqinnersection = "faq-module--faqinnersection--de5f6";
export var faqsection = "faq-module--faqsection--967ba";
export var open = "faq-module--open--060d8";
export var question = "faq-module--question--7f7e7";
export var questionText = "faq-module--questionText--d5ac3";