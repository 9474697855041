import React, { useRef } from "react";
import { AiOutlineUp, AiOutlineDown } from "react-icons/ai";
import {
  question,
  answersection,
  answer,
  questionText,
} from "./styles/faq.module.css";

const Question = ({ item, onToggle, index, activeQuestion }) => {
  const contentEl = useRef();
  return (
    <article className={question}>
      <header
        aria-hidden="true"
        onClick={() => {
          onToggle(index);
        }}
      >
        <h4 className={questionText}>{item.Que}</h4>
        <p className="btn">
          {activeQuestion === index ? (
            <AiOutlineUp color="#fcae16" />
          ) : (
            <AiOutlineDown color="#fcae16" />
          )}
        </p>
      </header>
      <div
        ref={contentEl}
        className={answersection}
        style={
          activeQuestion === index
            ? { height: contentEl.current.scrollHeight }
            : { height: "0px" }
        }
      >
        <p className={answer}>{item.Ans}</p>
      </div>
    </article>
  );
};

export default Question;
