import {
  VILLAGE_CAPITAL_IMAGE_PATH,
  APSSDC_IMAGE_PATH,
  TASK_IMAGE_PATH,
  AWS_MEMBER_IMAGE_PATH,
  NASSCOM_IMAGE_PATH,
  STARTUP_PORTAL_IMAGE_PATH,
  STARTUP_INDIA_IMAGE_PATH,
  ACELOT_IMAGE_PATH,
  AVSOY_IMAGE_PATH,
  BDI_IMAGE_PATH,
  CINEMA_FACTORY_IMAGE_PATH,
  FORESIET_IMAGE_PATH,
  GORDIAN_IMAGE_PATH,
  SOCIO_IMAGE_PATH,
  ULTRACURES_IMAGE_PATH,
  COCA_COLA_IMAGE_PATH,
  DADB_IMAGE_PATH,
  FARM_IMAGE_PATH,
  NEARBUY_IMAGE_PATH,
  SRM_IMAGE_PATH,
  TATA_IMAGE_PATH,
  TEDX_IMAGE_PATH,
  MIGUEL_IMAGE_PATH,
  POOJA_IMAGE_PATH,
  HIMANSHU_IMAGE_PATH,
} from "./localization";

export const billDetails = {
  subtotal: 8990,
  discount: 500,
  couponName: "SCHOOL50",
};

export const courseCardInfo = {
  name: "Full Stack Development For Beginners and Professionals",
  level: "ADVANCE",
  discountedTotal: 2499,
  total: 2999,
};

export const courseList = [
  {
    name: "Full Stack Development For Beginners and Professionals",
    level: "ADVANCE",
    discountedTotal: 2499,
    total: 2999,
  },
  {
    name: "Technology Stack",
    level: "ADVANCE",
    discountedTotal: 4499,
    total: 4999,
  },
];

export const awards = [
  {
    name: "Village Capital 2021 Cohort",
    description: "Winners of the Future of Work India 2021 Cohort",
    link: "https://vilcap.com/current-programs/future-of-work-india-2021",
    logoUrl: VILLAGE_CAPITAL_IMAGE_PATH,
  },
  {
    name: "Training Partner",
    description: "Official Training Partners",
    link: "https://apssdc.in/home/homepage",
    logoUrl: APSSDC_IMAGE_PATH,
  },
  {
    name: "Training Partner",
    description: "Official Training Partners",
    link: "https://www.task.telangana.gov.in/",
    logoUrl: TASK_IMAGE_PATH,
  },
  {
    name: "AWS - Edstart Member",
    description: "AWS Edstart Member",
    link: "https://aws.amazon.com/education/edstart/",
    logoUrl: AWS_MEMBER_IMAGE_PATH,
  },
  {
    name: "Nasscom",
    description: "Official Internship Partners with Nasscom",
    logoUrl: NASSCOM_IMAGE_PATH,
  },
  {
    name: "Startup Telangana",
    description: "Recognised by Telangana Government",
    logoUrl: STARTUP_PORTAL_IMAGE_PATH,
  },
];

export const jobCompanies = [
  {
    name: "Foresiet",
    logoUrl: FORESIET_IMAGE_PATH,
  },
  {
    name: "BDI",
    logoUrl: BDI_IMAGE_PATH,
  },
  {
    name: "Gordian",
    logoUrl: GORDIAN_IMAGE_PATH,
  },
  {
    name: "Company",
    logoUrl: ACELOT_IMAGE_PATH,
  },
  {
    name: "Socio Labs",
    logoUrl: SOCIO_IMAGE_PATH,
  },
  {
    name: "Cinema Factory",
    logoUrl: CINEMA_FACTORY_IMAGE_PATH,
  },
  {
    name: "AVSOY",
    logoUrl: AVSOY_IMAGE_PATH,
  },
  {
    name: "Ultracures",
    logoUrl: ULTRACURES_IMAGE_PATH,
  },
];

export const industryCoaches = [
  {
    name: "Coca-Cola",
    logoUrl: COCA_COLA_IMAGE_PATH,
  },
  {
    name: "Nearbuy",
    logoUrl: NEARBUY_IMAGE_PATH,
  },
  {
    name: "TCS",
    logoUrl: TATA_IMAGE_PATH,
  },
  {
    name: "TEDx",
    logoUrl: TEDX_IMAGE_PATH,
  },
  {
    name: "iFarm",
    logoUrl: FARM_IMAGE_PATH,
  },
  {
    name: "SRM Chennai",
    logoUrl: SRM_IMAGE_PATH,
  },
  {
    name: "DADB",
    logoUrl: DADB_IMAGE_PATH,
  },
];

export const faqs = [
  {
    question: "I am confused about the courses, who do I contact?",
    answer:
      "There are two ways to reach out to us if you're interested in a course or are confused about which one is the best fit for you. You can directly call us on +91 9154254541 and a Learning Consultant will help you out.",
  },
  {
    question: "Is there a demo lecture available?",
    answer:
      "Yes. Once you sign up with us, you will able to access and view few demo lessons in every course.",
  },
  {
    question: "How do I contact my coach?",
    answer:
      "You may contact your coach through multiple means. The easiest way is through the Discussion Board on your profile. Junior Coaches and Mentors on the platform contact you on a regular basis to help you with project completion and the learning process. Also, you can get in touch with the Coach through Email, WhatsApp and calls subject to availability of the Coach.",
  },
  {
    question: "How long is the course duration?",
    answer:
      "Courses at Unschool are self-paced. Take 2 weeks or 3 months – at Unschool, you are allowed to learn at your own pace and every course is designed to cater to your personal needs, which means you pay once and learn life-long.",
  },
  {
    question: "What are the modes of payment available?",
    answer:
      "Unschool caters to the Indian youth and hence the choices we provide in the payment methods are diverse. You may choose to pay directly on the website through Debit/Credit cards and Internet banking, and you will receive immediate access to the course. In addition, we also provide E-wallet options and direct UPI transfers. If you are a student who doesn’t use any of these electronic modes, you may choose to pay by cash to one of our Unschool Community Representatives or even directly deposit money in the bank. Write to us at [support@unschool.in](mailto:support@unschool.in) for more information.",
  },
  {
    question: "Why should I learn on Unschool?",
    answer:
      "Unschool is a melting pot of counter solutions to all the problems that exist in the education system. Unschool provides you with One-to-One Mentorship, Practical assessments, Certified Projects and Guaranteed Internship opportunities – all of this designed and validated by industry experts. Learning never ceases at Unschool. You have life-long access to your course and you will always receive updates on the Unschool Community that you can be a part of.",
  },
  {
    question: "Who are these industry experts who teach the course exactly?",
    answer:
      "Our coaches come with experience and are working with renowned institutions. Most of our coaches are young achievers. Some companies they represent are Inc42, Nearbuy, AIESEC, Leo Burnett etc.",
  },
];

export const awardImageUrl = [
  {
    name: "Village Capital 2021 Cohort",
    logoUrl: VILLAGE_CAPITAL_IMAGE_PATH,
  },
  {
    name: "Training Partner",
    logoUrl: APSSDC_IMAGE_PATH,
  },
  {
    name: "Training Partner",
    logoUrl: TASK_IMAGE_PATH,
  },
  {
    name: "AWS - Edstart Member",
    logoUrl: AWS_MEMBER_IMAGE_PATH,
  },
  {
    name: "Nasscom",
    logoUrl: NASSCOM_IMAGE_PATH,
  },
  {
    name: "Startup Telangana",
    logoUrl: STARTUP_PORTAL_IMAGE_PATH,
  },
  {
    name: "Startup India",
    logoUrl: STARTUP_INDIA_IMAGE_PATH,
  },
];

export const testimonials = [
  {
    name: "Miguel Magalhães",
    rating: "4.5",
    ratingsCount: "750+",
    testimonial:
      "As a young mentor, I’ve always identified with projects and initiatives that stand for education and youth development. Being a Senior Coach at Unschool is one of the best ways to contribute for both of these initiatives. It’s not just about the opportunity of teaching a specific skill to a number of students, it’s about giving them more tools to face the world and maximize their potential as professionals. In my Marketing Management course, I focus on soft skills such as strategic and critical thinking, and I truly believe that the process of delivering (on my side) and acquiring (on their side) these skills is a great contribution towards a new way of teaching and learning.",
    imgUrl: MIGUEL_IMAGE_PATH,
    credential: "Marketing Management",
  },
  {
    name: "Pooja Pathak",
    rating: "4.5",
    ratingsCount: "500+",
    testimonial:
      "It has been a refreshing experience to deliver the Public Relations course on Unschool platform. The entire platform is very user friendly and it makes it a lot easier to engage with the students as and when needed. I thoroughly enjoyed contributing about the communication industry on online learning platform and I am sure students will benefit from this experience. All the very best Unschol team for your future endeavors.",
    imgUrl: POOJA_IMAGE_PATH,
    credential: "Public Relation",
  },
  {
    name: "Himanshu Raghani",
    rating: "4.7",
    ratingsCount: "2490+",
    testimonial:
      "I honestly feel I've grown more in 2020 than I did in my last year of teaching. Everyone is willing to get their hands dirty to make you a better teacher. I am not afraid to try new things at unschool because I know there is a family to fall back on and learn from.",
    imgUrl: HIMANSHU_IMAGE_PATH,
    credential: "Finance and Stock Market",
  },
];

export const jobOpenings = [
  {
    title: "Chief Technology Officer",
    location: "Hyderabad",
    experience_years: "9-11 years",
    short_description:
      "Be part of an internship designed for the very best. Work in different departments and excel while still in college! Certified and paid.",
    job_type: "full-time",
  },
  {
    title: "ULead Youth Influencers",
    location: "Work From Home",
    experience_years: "0-1 years",
    short_description:
      "Be part of an internship designed for the very best. Work in different departments and excel while still in college! Certified and paid.",
    job_type: "cross-functional roles",
  },
];

export const productLines = [
  {
    title: "Skill Courses",
    slug: "skill-courses",
    variable: "skill_courses",
  },
  {
    title: "Internship Courses",
    slug: "internship-courses",
    variable: "internship_program",
  },
  { title: "Job Courses", slug: "job-courses", variable: "job_program" },
];
