import React from "react";
import TestimonialCard from "./TestimonialCard";
import JSON from "./data/testimonials.json";
import Slider from "react-slick";
// import "../styles/Testimonial.css"
import {
  maincontainertestimonials,
  testimonialstitle,
  containertestimonials,
} from "./styles/testimonial.module.css";

const Testimonials = () => {
  const settings = {
    dots: true,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 2,
    slidesToScroll: 2,

    // sdgdfsgefdsgsd

    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,

    responsive: [
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className={maincontainertestimonials}>
      <section className={testimonialstitle}>
        {/* <h2>Customers Love Unschool</h2> */}
      </section>
      <div className={containertestimonials}>
        <section>
          <Slider {...settings}>
            {JSON.TestimonialData.map((item, index) => (
              <TestimonialCard key={index} item={item} />
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Testimonials;
