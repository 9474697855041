import React from "react";
import JSON from "./data/learner.json";
import {
  learner_container,
  learner_card_container,
} from "./styles/learner.module.css";
const Marquee =
  typeof window !== `undefined`
    ? require("react-simple-marquee").default
    : null;
const Learners = () => {
  return (
    <div className={learner_container}>
      <h2>
        Hear it from our <span>Learners</span>
      </h2>

      <div className={learner_card_container}>
        {typeof window !== "undefined" && (
          <Marquee>
            {JSON.learnerData.map((learner, index) => (
              <img
                src={learner.img}
                alt=""
                style={{ width: 150, marginLeft: 20 }}
              />
            ))}
          </Marquee>
        )}
      </div>
    </div>
  );
};

export default Learners;
