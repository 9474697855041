// extracted by mini-css-extract-plugin
export var journeycontainer = "journey-module--journeycontainer--40cec";
export var journeysection = "journey-module--journeysection--83e02";
export var journeysectionrev = "journey-module--journeysectionrev--75975";
export var paddingleft = "journey-module--paddingleft--c4b1c";
export var paddingright = "journey-module--paddingright--e3c0b";
export var sectionleft = "journey-module--sectionleft--88e08";
export var sectionright = "journey-module--sectionright--38f7e";
export var sectionsubtitle = "journey-module--sectionsubtitle--81775";
export var usp_button_desktop = "journey-module--usp_button_desktop--574e7";
export var usp_button_mobile = "journey-module--usp_button_mobile--fea83";