// extracted by mini-css-extract-plugin
export var coachCategories = "coach-module--coach-categories--97f7f";
export var coachDesignation = "coach-module--coachDesignation--8ccd2";
export var coachName = "coach-module--coachName--01ab9";
export var coachcard = "coach-module--coachcard--23860";
export var coachcontent = "coach-module--coachcontent--95de1";
export var coachimg = "coach-module--coachimg--54037";
export var coachlogo = "coach-module--coachlogo--a10ba";
export var coachtitle = "coach-module--coachtitle--e1c32";
export var containercoach = "coach-module--containercoach--550ca";
export var maincoachtitle = "coach-module--maincoachtitle--06c71";
export var maincontainercoaches = "coach-module--maincontainercoaches--b9271";