export default {
  metaTags: {
    titleMetaTagContent:
      "Online Course Certification Program | NSDC Approved - Unschool",
    descriptionMetaTagContent:
      "Unschool offers online course certification programs that are also NSDC approved. Learn new skills and get certified by NSDC",
    keywordsMetaTagContent:
      "best online learning,best online certificate programs,online courses platform,online learning platform,online learning sites,online course certification,best online courses with certificates,best online certificate programs,Best learning app in India,top learning platform in India,online education platform,online courses free,online courses with certificate,online courses platform,online courses website,e learning platform for students,online internship program,online course certification with internship",
    robotsMetaTagContent: "index, follow",
    httpEquivMetaTagContent: "Content-Type",
    languageMetaTagContent: "English",
    revisitAfterMetaTagContent: "1 days",
    authorMetaTagContent: "Unschool",
  },
  scriptTags: [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      name: "Unschool",
      url: "https://www.unschool.in",
      logo: "https://www.unschool.in/static/Unschool%20Logo%20Horizontal%20White%20(1)-a4913f0c391a347aa53acfa994243cb4.png",
      alternateName: "Unschool-it",
      sameAs: [
        "https://www.instagram.com/unschool.it/",
        "https://www.linkedin.com/company/unschool-it/mycompany/",
      ],
    },

    {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: "E-Learning Platform",
      image:
        "https://www.unschool.in/static/Unschool%20Logo%20Horizontal%20White%20(1)-a4913f0c391a347aa53acfa994243cb4.png",
      description:
        "Unschool: We provide the best Online courses with internship programs from top companies in every stream in 2022.",
      brand: "Unschool",
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        bestRating: "5",
        worstRating: "3.5",
        ratingCount: "50087",
      },
      sku: "E-learning Platform",
      mpn: "UA-134257618-1",
    },
  ],
};
