// extracted by mini-css-extract-plugin
export var product_active = "productSection-module--product_active--27a41";
export var product_button_desktop = "productSection-module--product_button_desktop--56257";
export var product_button_mobile = "productSection-module--product_button_mobile--c2023";
export var product_names = "productSection-module--product_names--dbc98";
export var product_section_container = "productSection-module--product_section_container--76e94";
export var product_section_content = "productSection-module--product_section_content--06d80";
export var product_section_image = "productSection-module--product_section_image--b3b0d";
export var product_section_info = "productSection-module--product_section_info--97e70";
export var product_section_main = "productSection-module--product_section_main--cf8d5";
export var product_section_title = "productSection-module--product_section_title--fa85e";