import React from "react";
import SpotlightCard from "./SpotlightCard";
import JSON from "./data/spotlight.json";
import Slider from "react-slick";
// import "../styles/Testimonial.css"
import {
  main_container_spotlight,
  spotlight_title,
  spotlight_card_container,
} from "./styles/spotlight.module.css";

const Spotlight = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    initialSlide: 0,
    infinite: true,
    responsive: [
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className={main_container_spotlight}>
      <section className={spotlight_title}>
        <h2>
          We <span>Unschooled</span> It!
        </h2>
      </section>
      <div className={spotlight_card_container}>
        <section>
          <Slider {...settings}>
            {JSON.spotlightData.map((item, index) => (
              <SpotlightCard key={index} item={item} />
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Spotlight;
