import React from "react";
import {
  coursecard,
  courseimg,
  coursecontent,
} from "./styles/course.module.css";
import "./styles/course.css";
const CourseCard = ({ item }) => {
  return (
    <section className={coursecard}>
      <div className={courseimg}>
        <img src={item.img} alt="" />
      </div>
      <div className={coursecontent}>
        <h3>{item.title}</h3>
        <p>{item.coachname}</p>
      </div>
    </section>
  );
};

export default CourseCard;
