import React from "react";
import { useState } from "react";
import Button from "./Button";
import JSON from "./data/product.json";
import {
  product_section_container,
  product_section_title,
  product_section_main,
  product_names,
  product_active,
  product_section_content,
  product_section_image,
  product_section_info,
  product_button_desktop,
  product_button_mobile,
} from "./styles/productSection.module.css";

const ProductSection = () => {
  const [product, setProduct] = useState("skill");
  const [filterProduct, setFiterProduct] = useState([JSON.productData[0]]);

  const handleProductChange = (product_name) => {
    setProduct(product_name);
    console.log(filterProduct, product);
    if (product_name === "skill") {
      setFiterProduct([JSON.productData[0]]);
    } else if (product_name === "internship") {
      setFiterProduct([JSON.productData[1]]);
    } else if (product_name === "job") {
      setFiterProduct([JSON.productData[2]]);
    }
  };
  return (
    <div className={product_section_container}>
      <div className={product_section_title}>
        <h2>
          Explore<span>.</span> Upskill<span>.</span> Build
        </h2>
      </div>
      <div className={product_section_main}>
        <div className={product_names}>
          <h4
            onClick={() => handleProductChange("skill")}
            className={product === "skill" ? product_active : ""}
          >
            Skill Courses
          </h4>
          <h4
            onClick={() => handleProductChange("internship")}
            className={product === "internship" ? product_active : ""}
          >
            Internship Programs
          </h4>
          <h4
            onClick={() => handleProductChange("job")}
            className={product === "job" ? product_active : ""}
          >
            Job Programs
          </h4>
        </div>
        {filterProduct.map((productItem, index) => {
          return (
            <div className={product_section_content} key={index}>
              <div className={product_section_info}>
                <h3>
                  {productItem.titleb}
                  <span>{productItem.highlight}</span> {productItem.titlea}
                </h3>
                <p></p>
                <ul>
                  <li>
                    <img
                      src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669887252/Unschool/product/Group_26844_kkxaok.png"
                      alt=""
                    />
                    {productItem.point1}
                  </li>
                  <li>
                    <img
                      src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669887252/Unschool/product/Group_26844_kkxaok.png"
                      alt=""
                    />
                    {productItem.point2}
                  </li>{" "}
                  <li>
                    <img
                      src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669887252/Unschool/product/Group_26844_kkxaok.png"
                      alt=""
                    />

                    {productItem.point3}
                  </li>
                </ul>
                <Button
                  text={productItem.buttonText}
                  source={productItem.link}
                  cName={product_button_desktop}
                />
              </div>
              <div className={product_section_image}>
                <img src={productItem.img} alt={productItem.imgAlt} />
              </div>

              <Button
                text={productItem.buttonText}
                source={productItem.link}
                cName={product_button_mobile}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductSection;
