import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  herocontainer,
  herocontent,
  herotitle,
  heroimage,
  buttonWrapper,
  seven_day_subext,
} from "./styles/hero.module.css";
// import {
//   HAS_FREE_PLAN,
//   HAS_SUBSCRIBED_TO_PLAN,
//   LMS_PLATFORM_OUT_LINK,
//   LOADING,
// } from "../../utils/localization";
// import { useMutation } from "@apollo/client";
// import { ADD_CART_ITEM } from "../../../graphql/mutations/cart";
// import { bindActionCreators } from "redux";
// import { cartDetailsActions, helperActions } from "../../utils/redux/actions";
// import { variants } from "../../utils/variants";
// import {
//   checkUserHasFreePlan,
//   checkUserHasSubscribedToPlan,
// } from "../../utils/helpers/component";

const Hero = () => {
  // const dispatch = useDispatch();

  // const userPlans = useSelector((state) => state.userPlans.plans);
  // const cartDetails = useSelector((state) => state.cartDetails);
  // const userDetails = useSelector((state) => {
  //   return state?.user?.userDetails;
  // });

  // const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  // const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  // const [addItemToCart] = useMutation(ADD_CART_ITEM);

  // const isPlanSelected = cartDetails?.cartItems?.find(
  //   (cartItem) => cartItem.itemable.__typename === "Plan"
  // );

  // const hasSubscribed = checkUserHasSubscribedToPlan(userDetails);
  // const hasFreePlan = checkUserHasFreePlan(userPlans);

  // const naviateToCart = () => {
  //   window.location.href = "/checkout";
  // };

  // const naviateToDashboard = () => {
  //   window.open(LMS_PLATFORM_OUT_LINK, "_blank");
  // };

  // const handleFreePlanAddToCart = async () => {
  //   try {
  //     const { data } = await addItemToCart({
  //       variables: cartDetails?.uuid
  //         ? {
  //             itemableType: "plan",
  //             itemableId: userPlans.filter(
  //               (plan) => plan.chargebeeName === null
  //             )[0].id,
  //             cartUuid: cartDetails?.uuid,
  //           }
  //         : {
  //             itemableType: "plan",
  //             itemableId: userPlans.filter(
  //               (plan) => plan.chargebeeName === null
  //             )[0].id,
  //           },
  //     });
  //     if (data?.cartItemAdd) {
  //       setCartDetails(data?.cartItemAdd);
  //     }
  //   } catch (error) {
  //     const gqlError = error.graphQLErrors[0];
  //     if (gqlError) openToastMessage({ variant: variants.error });
  //   }
  // };

  // const handlePlanAddToCart = async () => {
  //   try {
  //     const { data } = await addItemToCart({
  //       variables: cartDetails?.uuid
  //         ? {
  //             itemableType: "plan",
  //             itemableId: userPlans.filter(
  //               (plan) => plan.chargebeeName !== null
  //             )[0].id,
  //             cartUuid: cartDetails?.uuid,
  //           }
  //         : {
  //             itemableType: "plan",
  //             itemableId: userPlans.filter(
  //               (plan) => plan.chargebeeName !== null
  //             )[0].id,
  //           },
  //     });
  //     if (data?.cartItemAdd) {
  //       setCartDetails(data?.cartItemAdd);
  //     }
  //   } catch (error) {
  //     const gqlError = error.graphQLErrors[0];
  //     if (gqlError) openToastMessage({ variant: variants.error });
  //   }
  // };

  // const renderButton = () => {
  //   if (isPlanSelected) {
  //     return (
  //       <div onClick={naviateToCart} className="button-container">
  //         <div className="button">
  //           <p>GO TO CART</p>
  //         </div>
  //       </div>
  //     );
  //   } else if (hasSubscribed === HAS_SUBSCRIBED_TO_PLAN) {
  //     return (
  //       <div onClick={naviateToDashboard} className="button-container">
  //         <div className="button">
  //           <p>GO TO DASHBOARD</p>
  //         </div>
  //       </div>
  //     );
  //   } else {
  //     if (hasFreePlan === LOADING) {
  //       return (
  //         <div className="button-container">
  //           <div className="button">
  //             <p>LOADING ...</p>
  //           </div>
  //         </div>
  //       );
  //     } else if (hasFreePlan === HAS_FREE_PLAN) {
  //       return (
  //         <div onClick={handleFreePlanAddToCart} className="button-container">
  //           <div className="button">
  //             <p>ENROLL NOW</p>
  //           </div>
  //         </div>
  //       );
  //     } else {
  //       return (
  //         <div onClick={handlePlanAddToCart} className="button-container">
  //           <div className="button">
  //             <p>ENROLL NOW</p>
  //           </div>
  //         </div>
  //       );
  //     }
  //   }
  // };

  const renderButton = () => {
    return (
      <div className="button-container">
        <div className="button">


          <p>Get started for free</p>


        </div>
      </div>
    );
  };

  return (
    <div className={herocontainer}>
      <div className={herocontent}>
        <div className={herotitle}>
          <h1>India's First Streaming Platform For Learning</h1>
          <p>
            A platform to learn new things, upskill your career, and explore
            your interests.
          </p>
        </div>
        <div className={buttonWrapper}>{renderButton()}</div>
        <div className={seven_day_subext}>
          <span>7 days free trial at 0 cost</span>
        </div>
      </div>

      <div className={heroimage}>
        <img
          src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669639991/Unschool/hero/Group_26774_m8f848.png"
          alt="Hero GIF coaches"
        />
      </div>
    </div>
  );
};

export default Hero;
