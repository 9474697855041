import React from "react";
import {
  numbercontainer,
  numberinner,
  numberitem,
} from "./styles/numbers.module.css";
const Numbers = () => {
  return (
    <div className={numbercontainer}>
      <div className={numberinner}>
        <div className={numberitem}>
          <h4>200K+</h4>
          <p>Learners</p>
        </div>

        <div className={numberitem}>
          <h4>500K</h4>
          <p>Enrollments</p>
        </div>

        <div className={numberitem}>
          <h4>100%</h4>
          <p>Placements</p>
        </div>

        <div className={numberitem}>
          <h4>150+</h4>
          <p>Industry experts</p>
        </div>

        <div className={numberitem}>
          <h4>110+</h4>
          <p>Hiring partners</p>
        </div>
      </div>
    </div>
  );
};

export default Numbers;
