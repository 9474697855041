import React, { useEffect } from "react";
import CourseCard from "./CourseCard";
import { useState } from "react";
import JSON from "./data/courses.json";
import Slider from "react-slick";
import {
  maincontainercourse,
  coursetitle,
  containercourse,
  coursecategories,
  activee,
  Category_title,
} from "./styles/course.module.css";
import "./styles/course.css";

const Courses = () => {
  const [filterItems, setFilterItems] = useState(JSON.CourseData);
  const [active, setactive] = useState("Vocational");
  useEffect(() => {
    const FirstLoad = JSON.CourseData.filter(
      (item) => item.category === "Vocational"
    );
    setFilterItems(FirstLoad);
  }, []);

  // const isBrowser = typeof window !== "undefined" && window;
  // console.log(
  //   isBrowser.document.window.document.getElementsByClassName({
  //     maincontainercourse,
  //   })
  // );

  const filterHandler = (cate) => {
    const filterI = JSON.CourseData.filter((item) => item.category === cate);
    setFilterItems(filterI);
    setactive(cate);
  };

  useEffect(() => {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 776,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
  }, [active]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 776,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className={maincontainercourse}>
      <section className={coursetitle}>
        <h2>
          Lifelong <span>Learning</span>
        </h2>
      </section>
      <div className={containercourse}>
        <section>
          <div className={coursecategories}>
            <h4
              onClick={() => {
                const FirstLoad = JSON.CourseData.filter(
                  (item) => item.Featured === true
                );

                setFilterItems(FirstLoad);
                setactive("All Categories");
              }}
              className={
                active === "All Categories"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              All Categories
            </h4>
            <h4
              onClick={() => filterHandler("Vocational")}
              className={
                active === "Vocational"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Vocational
            </h4>
            <h4
              onClick={() => filterHandler("Marketing")}
              className={
                active === "Marketing"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Marketing
            </h4>
            <h4
              onClick={() => filterHandler("Management")}
              className={
                active === "Management"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Management
            </h4>
            <h4
              onClick={() => filterHandler("Software")}
              className={
                active === "Software"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Software
            </h4>
            <h4
              onClick={() => filterHandler("Coding")}
              className={
                active === "Coding"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Coding
            </h4>

            <h4
              onClick={() => filterHandler("Data")}
              className={
                active === "Data"
                  ? `${activee + " " + Category_title}`
                  : `${Category_title}`
              }
            >
              Data
            </h4>
          </div>
        </section>

        <section>
          <Slider {...settings}>
            {filterItems.map((item, index) => (
              <CourseCard key={index} item={item} />
            ))}
          </Slider>
        </section>
      </div>
    </div>
  );
};

export default Courses;
