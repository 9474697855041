import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HAS_FREE_PLAN,
  HAS_SUBSCRIBED_TO_PLAN,
  LMS_PLATFORM_OUT_LINK,
  LOADING,
} from "../../utils/localization";
import { useMutation } from "@apollo/client";
import { ADD_CART_ITEM } from "../../../graphql/mutations/cart";
import { bindActionCreators } from "redux";
import { cartDetailsActions, helperActions } from "../../utils/redux/actions";
import { variants } from "../../utils/variants";
import {
  checkUserHasFreePlan,
  checkUserHasSubscribedToPlan,
} from "../../utils/helpers/component";

const FloatButton = () => {
  const [show, setshow] = useState(false);
  const [mshow, msetshow] = useState(false);

  const dispatch = useDispatch();

  const userPlans = useSelector((state) => state.userPlans.plans);
  const cartDetails = useSelector((state) => state.cartDetails);
  const userDetails = useSelector((state) => {
    return state?.user?.userDetails;
  });

  const { setCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const [addItemToCart] = useMutation(ADD_CART_ITEM);

  const isPlanSelected = cartDetails?.cartItems?.find(
    (cartItem) => cartItem.itemable.__typename === "Plan"
  );

  const hasSubscribed = checkUserHasSubscribedToPlan(userDetails);
  const hasFreePlan = checkUserHasFreePlan(userPlans);

  const isBrowser = typeof window !== "undefined" && window;

  const scrolling = () => {
    if (isBrowser.scrollY > 700 && isBrowser.screen.width > 1200) {
      return setshow(true);
    } else if (isBrowser.scrollY > 700 && isBrowser.screen.width < 776) {
      return msetshow(true);
    } else {
      setshow(false);
      msetshow(false);
    }
  };

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", scrolling);
  }

  const naviateToCart = () => {
    window.location.href = "/checkout";
  };

  const naviateToDashboard = () => {
    window.open(LMS_PLATFORM_OUT_LINK, "_blank");
  };

  const handleFreePlanAddToCart = async () => {
    try {
      const { data } = await addItemToCart({
        variables: cartDetails?.uuid
          ? {
              itemableType: "plan",
              itemableId: userPlans.filter(
                (plan) => plan.chargebeeName === null
              )[0].id,
              cartUuid: cartDetails?.uuid,
            }
          : {
              itemableType: "plan",
              itemableId: userPlans.filter(
                (plan) => plan.chargebeeName === null
              )[0].id,
            },
      });
      if (data?.cartItemAdd) {
        setCartDetails(data?.cartItemAdd);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const handlePlanAddToCart = async () => {
    try {
      const { data } = await addItemToCart({
        variables: cartDetails?.uuid
          ? {
              itemableType: "plan",
              itemableId: userPlans.filter(
                (plan) => plan.chargebeeName !== null
              )[0].id,
              cartUuid: cartDetails?.uuid,
            }
          : {
              itemableType: "plan",
              itemableId: userPlans.filter(
                (plan) => plan.chargebeeName !== null
              )[0].id,
            },
      });
      if (data?.cartItemAdd) {
        setCartDetails(data?.cartItemAdd);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const renderButton = () => {
    if (isPlanSelected) {
      return <div onClick={naviateToCart}>Go To Cart</div>;
    } else if (hasSubscribed === HAS_SUBSCRIBED_TO_PLAN) {
      return <div onClick={naviateToDashboard}>Go To Dashboard</div>;
    } else {
      if (hasFreePlan === LOADING) {
        return <div>LOADING ...</div>;
      } else if (hasFreePlan === HAS_FREE_PLAN) {
        return <div onClick={handleFreePlanAddToCart}>Enroll Now</div>;
      } else {
        return <div onClick={handlePlanAddToCart}>Enroll Now</div>;
      }
    }
  };

  return (
    <div
      className={
        mshow ? "float-button-container show" : "float-button-container"
      }
    >
      <div className={show || mshow ? "float-btn show" : "float-btn"}>
        {renderButton()}
      </div>
    </div>
  );
};

export default FloatButton;
