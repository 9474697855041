import React from "react";
import Vocational from "./Seo_list_components/Vocational";
import Marketing from "./Seo_list_components/Marketing";
import Software from "./Seo_list_components/Software";
import {
  seo_list_container,
  seo_list_main_container,
} from "./styles/Seo_List.module.css";
import Coding from "./Seo_list_components/Coding";
import Management from "./Seo_list_components/Management";
import Data from "./Seo_list_components/Data";

const Seo_List = () => {
  return (
    <div className={seo_list_container}>
      <div className={seo_list_main_container}>
        <Vocational />
        <Marketing />
        <Software />
        <Coding />
        <Management />
        <Data />
      </div>
    </div>
  );
};

export default Seo_List;
