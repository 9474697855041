import React from "react";
import ImageDividerone from "./ImageDividerone";
import JSON from "./data/ImageDivider.json";
import Slider from "react-slick";
import { containerdivider } from "./styles/divider.module.css";

// import "../styles/imagedivider.css"

const DividerContainer = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };

  return (
    <div className={containerdivider}>
      <Slider {...settings}>
        {JSON.CoachData.map((item, index) => (
          <ImageDividerone key={index} item={item} />
        ))}
      </Slider>
    </div>
  );
};

export default DividerContainer;
