import React, { useRef } from "react";
import PropTypes from "prop-types";
import useSmoothScroll from "react-smooth-scroll-hook";

export const CTAButton = ({
  text,
  id,
  className,
  _onClick,
  scrollToEl,
  Noborder,
  link,
  mailto,
}) => {
  let ref = useRef(
    typeof window !== "undefined" ? document.documentElement : null
  );

  // if (typeof window !== "undefined") {
  //   ref.current = document.documentElement;
  // }

  const { scrollTo } = useSmoothScroll({
    ref,
    // speed: 250,
    direction: "y",
  });

  let onClick;

  if (link) {
    onClick = () => window.open(link, "_blank");
  } else if (scrollToEl) {
    onClick = () => {
      scrollTo(scrollToEl);
    };
  } else if (_onClick) {
    onClick = _onClick;
  } else {
    onClick = () => {};
  }

  return (
    <div className={`cta_button_wrapper ${className ? className : ""}`}>
      <div className={"cta_button_wrapper_inner"}>
        <div className={Noborder ? "" : "cta_button_border"} />
        {mailto ? (
          <a className="cta_button mailto-button" href={link}>
            {text}
          </a>
        ) : (
          <button className="cta_button" id={id} onClick={onClick}>
            {text}
          </button>
        )}
      </div>
    </div>
  );
};

CTAButton.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  mailto: PropTypes.bool,
};

export default CTAButton;
