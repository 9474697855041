// extracted by mini-css-extract-plugin
export var containertestimonials = "testimonial-module--containertestimonials--4c054";
export var maincontainertestimonials = "testimonial-module--maincontainertestimonials--ccab9";
export var name = "testimonial-module--name--1bcb7";
export var quote = "testimonial-module--quote--4c883";
export var slickDots = "testimonial-module--slick-dots--b43d2";
export var slickList = "testimonial-module--slick-list--d6f0b";
export var slickNext = "testimonial-module--slick-next--b930c";
export var slickPrev = "testimonial-module--slick-prev--57dc4";
export var testimonialcard = "testimonial-module--testimonialcard--4bc84";
export var testimonialcardtop = "testimonial-module--testimonialcardtop--f00b9";
export var testimonialcontent = "testimonial-module--testimonialcontent--61d52";
export var testimonialimg = "testimonial-module--testimonialimg--572e8";
export var testimonialname = "testimonial-module--testimonialname--52b08";
export var testimonialstitle = "testimonial-module--testimonialstitle--7ce9a";