import React from "react";
import "./styles/style.css";
import {
  powercontainer,
  powertitle,
  powerlogos,
  row1,
  row2,
} from "./styles/poweredby.module.css";
const PoweredBy = () => {
  return (
    <div>
      <div className={powercontainer}>
        <div className={powertitle}>
          <h2>We’re trusted, backed and powered by the best in the industry</h2>
        </div>
        <div className={powerlogos}>
          <div className={row1}>
            <img


              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2071_s7fmb4.png"
              alt="online learning platform in Hyderabad"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2074_y2pohm.png"
              alt="free e learning platform"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2075_pxotci.png"
              alt="free trial for online courses"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2077_faieiy.png"
              alt="e learning mobile app"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2079_bjktra.png"
              alt="best free e learning platform"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2082_om1ise.png"


              alt="online learning centre"
            />
          </div>
          <div className={row2}>
            <img


              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2078_i91xzq.png"


              alt="best free e learning platform"
            />

            <img


              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2081_xdp3nm.png"
              alt="Free online courses"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2083_dlwrxi.png"
              alt="E- Learning library"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2080_gbcja0.png"
              alt="online courses for beginners"
            />
            <img
              src="https://res.cloudinary.com/di3tuzgef/image/upload/v1670048180/Unschool/Trusted%20By/Frame_2084_ga7x4k.png"
              alt="short online courses"
            />


          </div>
        </div>
      </div>
    </div>
  );
};

export default PoweredBy;
