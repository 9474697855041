import React from "react";

import Button from "./Button";
// import { StaticImage } from "gatsby-plugin-image"
// import "../styles/Journey.css"

import {
  journeycontainer,
  journeysection,
  sectionleft,
  paddingleft,
  sectionsubtitle,
  sectionright,
  paddingright,
  journeysectionrev,
  usp_button_desktop,
  usp_button_mobile,
} from "./styles/journey.module.css";

const Journey = () => {
  return (
    <div className={journeycontainer}>
      <div className={journeysection}>
        <div className={`${sectionleft + " " + paddingleft}`}>
          <h2>
            {/* Build your business,
            <br />
            hobby or career. */}
            Self-Paced Learning
          </h2>

          <p className={sectionsubtitle}>
            Learn at your own pace. You could earn higher grades because you can
            learn at your own pace. You'll benefit from a flexible schedule and
            not to forget access to course materials 24 hours a day, 7 days a
            week.
          </p>
          <Button
            text={"Learn at your comfort"}
            source={"https://rzp.io/l/61RdShX"}
            cName={usp_button_desktop}
          />
        </div>
        <div className={sectionright}>
          {/* <img
            src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669637080/Unschool/uspSection/Group_26721_aaegba.png"
            alt=""
          /> */}
          <video width="320" autoPlay muted loop>
            <source
              src="https://res.cloudinary.com/di3tuzgef/video/upload/v1669961483/Unschool/uspSection/gif_lms_new_1_y2fwlj.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <Button
          text={"Learn at your comfort"}
          source={"https://rzp.io/l/61RdShX"}
          cName={usp_button_mobile}
        />
      </div>

      <div className={`${journeysection + " " + journeysectionrev}`}>
        <div className={`${sectionleft + " " + paddingright}`}>
          <h2>
            {/* A Community <br /> to Support You */}
            Community Access
          </h2>
          <p className={sectionsubtitle}>
            Learn at your own pace. You could earn higher grades because you can
            learn at your own pace. You'll benefit from a flexible schedule and
            not to forget access to course materials 24 hours a day, 7 days a
            week.
          </p>
          <Button
            text={"Know your peers"}
            source={"https://rzp.io/l/61RdShX"}
            cName={usp_button_desktop}
          />
        </div>

        <div className={sectionright}>
          {/* <img
            src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669637080/Unschool/uspSection/Group_26721_aaegba.png"
            alt=""
          /> */}

          <video width="320" autoPlay muted loop>
            <source
              src="https://res.cloudinary.com/di3tuzgef/video/upload/v1669961489/Unschool/uspSection/lms_community_new_1_g1mhjd.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <Button
          text={"Know your peers"}
          source={"https://rzp.io/l/61RdShX"}
          cName={usp_button_mobile}
        />
      </div>

      <div className={journeysection}>
        <div className={`${sectionleft + " " + paddingleft}`}>
          <h2>
            {/* Learning For
            <br /> Real Life */}
            Get certified
          </h2>
          <p className={sectionsubtitle}>
            Learn at your own pace. You could earn higher grades because you can
            learn at your own pace. You'll benefit from a flexible schedule and
            not to forget access to course materials 24 hours a day, 7 days a
            week.
          </p>
          <Button
            text={"Enroll now"}
            source={"https://rzp.io/l/61RdShX"}
            cName={usp_button_desktop}
          />
        </div>
        <div className={sectionright}>
          {/* <StaticImage
            src="https://static.skillshare.com/cdn-cgi/image/quality=85,format=auto/assets/images/homepage/three-beat-story/en/LOH_WhatWillYouDiscover_2096px.png"
            placeholder="tracedSVG"
            alt="Explore-courses"
            layout="fixed"
            height={576}
            width={1048}
            quality={100}
          /> */}

          {/* <img
            src="https://res.cloudinary.com/di3tuzgef/image/upload/v1669637080/Unschool/uspSection/Group_26721_aaegba.png"
            alt=""
          /> */}

          <video width="320" autoPlay muted loop>
            <source
              src="https://res.cloudinary.com/di3tuzgef/video/upload/v1669961492/Unschool/uspSection/gif_certificate_new_1_tr04ka.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <Button
          text={"Enroll now"}
          source={"https://rzp.io/l/61RdShX"}
          cName={usp_button_mobile}
        />
      </div>
    </div>
  );
};

export default Journey;
