import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";
import Showdown from "showdown";
// import Hero from "../components/Hero";
import AwardsRow from "../components/AwardsRow";
import BenefitsSection from "../components/BenefitsSection";
import TrendingCourses from "../components/TrendingCourses";
import StatsSection from "../components/StatsSection";
import IndustryCoaches from "../components/IndustryCoaches";
import FAQSection from "../components/FAQSection";
import TopCategoriesSection from "../components/TopCategoriesSection";
import { useStaticQuery, graphql } from "gatsby";
import { useSelector } from "react-redux";
import { faqs } from "../utils/constants";
import SkillCourses from "../components/SkillCourses";
import InternshipPrograms from "../components/InternshipPrograms";
import homePageSeoetails from "../constants/homePageSeoDetails";

// skill course import
import { Helmet } from "react-helmet";
import "../components/unschoolComponents/styles/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hero from "../components/unschoolComponents/Hero";
import PoweredBy from "../components/unschoolComponents/PoweredBy";
import Courses from "../components/unschoolComponents/Courses";
import DividerContainer from "../components/unschoolComponents/DividerContainer";
import Journey from "../components/unschoolComponents/Journey";
import Coaches from "../components/unschoolComponents/Coaches";

import Testimonials from "../components/unschoolComponents/Testimonials";
import Numbers from "../components/unschoolComponents/Numbers";
import Business from "../components/unschoolComponents/Business";
import Faq from "../components/unschoolComponents/Faq";

import FloatButton from "../components/unschoolComponents/FloatButton";
import Seo_List from "../components/unschoolComponents/Seo_List";

import LayoutWithOutPadding from "../components/LayoutWithOutPadding";
import Learners from "../components/unschoolComponents/Learners";
import ProductSection from "../components/unschoolComponents/ProductSection";
import KsDividerContainer from "../components/unschoolComponents/KsDividerContainer";
import Spotlight from "../components/unschoolComponents/Spotlight";
const Revamp = () => {
  //   query PublicCourseList {
  //     api {
  //       courses(
  //         paging: { pageNo: 1, perPage: 3000 }
  //         filters: { isTrending: true }
  //       ) {
  //         data {
  //           id
  //           title
  //           shortDescription
  //           description
  //           overviews
  //           duration
  //           slug
  //           chaptersCount
  //           isTrending
  //           price
  //           coverImage {
  //             filename
  //             id
  //             url
  //           }
  //           coaches {
  //             firstName
  //             lastName
  //             userType
  //             profilePicture {
  //               url
  //             }
  //           }
  //           careerAhead
  //           productLine
  //         }
  //       }
  //       seriesList(paging: { pageNo: 1, perPage: 3000 }) {
  //         data {
  //           id
  //           title
  //           shortDescription
  //           duration
  //           slug
  //           chaptersCount
  //           price
  //           coverImage {
  //             filename
  //             id
  //             url
  //           }
  //           courses {
  //             id
  //             isTrending
  //           }
  //         }
  //       }
  //       categoryCourseList(paging: { pageNo: 1, perPage: 3000 }) {
  //         data {
  //           id
  //           name
  //           profilePicture {
  //             id
  //             filename
  //             url
  //           }
  //           slug
  //         }
  //       }
  //     }
  //   }
  // `);

  // const publicCourseList = publicCourseListData?.api?.courses?.data ?? [];
  // const publicSeriesCourseList =
  //   publicCourseListData?.api?.seriesList?.data ?? [];
  // const publicCategoryCourseList =
  //   publicCourseListData?.api?.categoryCourseList?.data ?? [];

  const pageTitle = `Home | ${homePageSeoetails?.metaTags?.titleMetaTagContent}`;

  const pageDescription =
    homePageSeoetails?.metaTags?.descriptionMetaTagContent;
  const keywords = homePageSeoetails?.metaTags?.keywordsMetaTagContent;
  const robots = homePageSeoetails?.metaTags?.robotsMetaTagContent;
  const httpEquiv = homePageSeoetails?.metaTags?.httpEquivMetaTagContent;
  const language = homePageSeoetails?.metaTags?.languageMetaTagContent;
  const revisitAfter = homePageSeoetails?.metaTags?.revisitAfterMetaTagContent;
  const author = homePageSeoetails?.metaTags?.authorMetaTagContent;
  const schemaMarkup = homePageSeoetails?.scriptTags;

  return (
    <LayoutWithOutPadding>
      <Helmet>
        <title>Rv: India’s Best Free E-Learning Library | Unschool</title>
        <meta
          name="description" 
          content="Introducing 'OTT for learning. Explore the E-Learning library with 500+ courses and still counting. Start your 7 days free trial now!"
        />
        <link
          rel="apple-touch-icon"
          sizes="256x256"
          href="https://www.unschool.in/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="https://www.unschool.in/img/apple-touch-icon.png"
          sizes="256x256"
        />
      </Helmet>
      <FloatButton />
      <Hero />
      <PoweredBy />
      <ProductSection />
      <Journey />
      <Coaches />
      <KsDividerContainer />
      <Courses />
      <Numbers />
      <DividerContainer />
      <Learners />
      <Testimonials />
      <Spotlight />
      <Faq />
    </LayoutWithOutPadding>
  );
};

export default Revamp;
