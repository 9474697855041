import React from "react";
import TrendingCourses from "../TrendingCourses";
import { useStaticQuery, graphql } from "gatsby";
import { isEmpty } from "lodash";

const InternshipPrograms = () => {
  const internshipProgramsCoursesListData = useStaticQuery(graphql`
    query InternshipProgramsCourseList {
      api {
        courses(
          paging: { pageNo: 1, perPage: 3000 }
          filters: { productLine: internship_program }
        ) {
          data {
            id
            title
            shortDescription
            description
            overviews
            duration
            slug
            chaptersCount
            isTrending
            price
            coverImage {
              filename
              id
              url
            }
            coaches {
              firstName
              lastName
              userType
              profilePicture {
                url
              }
            }
            careerAhead
            productLine
          }
        }
      }
    }
  `);

  const internshipCourseList =
    internshipProgramsCoursesListData?.api?.courses?.data ?? [];

  return (
    !isEmpty(internshipCourseList) && (
      <TrendingCourses
        internshipProgram
        courses={internshipCourseList}
        name="Internship Programs"
      />
    )
  );
};

export default InternshipPrograms;
